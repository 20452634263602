<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Name -->
                  <b-col
                    lg="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="survey.name"
                          maxlength="300"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="survey.reference_id"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('CATEGORY')"
                      label-for="id-category"
                    >
                      <v-select
                        id="id-category"
                        v-model="category"
                        :placeholder="$t('SELECT_CATEGORY')"
                        label="name"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="categoryOptions"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Survey -->
                  <b-col
                    v-if="survey.ID===0"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('COPIED_FROM')"
                      label-for="id-survey-copy"
                    >
                      <v-select
                        id="id-survey-copy"
                        v-model="copySurvey"
                        :placeholder="$t('SELECT_SURVEY')"
                        label="name"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="allSurveys"
                        @input="handleChangeSurvey($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Status -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('STATUS')"
                      label-for="id-status"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('STATUS')"
                        rules="required"
                      >
                        <v-select
                          id="id-status"
                          v-model="survey.survey_status"
                          :placeholder="$t('SELECT_STATUS')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="surveyStatus"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Sequence -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('SEQUENCE')"
                      label-for="id-sequence"
                    >
                      <v-select
                        id="id-sequence"
                        v-model="survey.sequence"
                        :placeholder="$t('SELECT_SEQUENCE')"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="surveySequence"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Frequency -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('FREQUENCY')"
                      label-for="id-frequency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('FREQUENCY')"
                        rules="required"
                      >
                        <v-select
                          id="id-frequency"
                          v-model="survey.frequency"
                          :placeholder="$t('SELECT_FREQUENCY')"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="surveyFrequency"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Company -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="companyname"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="companies"
                          @input="handleChangeCompany($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Project -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('Project')"
                      label-for="id-project"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Project')"
                        rules="required"
                      >
                        <v-select
                          id="id-project"
                          v-model="project"
                          :placeholder="$t('SELECT_PROJECT')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="projects"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Strat date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="survey.start_date"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- End date -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <flat-pickr
                        id="id-end-date"
                        v-model="survey.end_date"
                        class="form-control"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Lenght -->
                  <b-col
                    v-if="survey.frequency !== surveyFrequency[0]"
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('DURATION')"
                      label-for="id-length"
                    >
                      <b-form-input
                        id="id-length"
                        v-model="survey.length"
                        type="number"
                        maxlength="3"
                        :placeholder="$t('DURATION')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Scope name -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('SCOPE')"
                      label-for="id-scope"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SCOPE')"
                        rules="required"
                      >
                        <v-select
                          id="id-scope"
                          v-model="scope"
                          :placeholder="$t('SELECT_SCOPE')"
                          :clearable="false"
                          class="flex-grow-1"
                          label="name"
                          :options="scopeOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Analyze Individual -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        id="checkbox-is_analyze_individual"
                        v-model="survey.is_analyze_individual"
                        name="checkbox-is_analyze_individual"
                        class="mt-2"
                        value="1"
                        unchecked-value="0"
                      >
                        {{ $t('AI_INDIVIDUAL_FEEDBACK') }}
                      </b-form-checkbox>
                    </b-form-group>

                  </b-col>
                  <!-- Description -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="survey.description"
                        maxlength="3000"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Submit and Reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ survey.ID ? $t("BUTTON.UPDATE") : $t("BUTTON.ADD") }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t("BUTTON.RESET") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { formatDate, avatarText } from '@core/utils/filter'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'
import { resolveUserRole } from '@/constants/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [mixinList],
  data() {
    return {
      survey: {
        ID: 0,
        reference_id: '',
        copy_id: 0,
        name: '',
        start_date: '',
        end_date: null,
        total_users: 0,
        total_responses: 0,
        length: 0,
        survey_status: '',
        frequency: '',
        sequence: '',
        language: '',
        project_id: 0,
        company_id: 0,
        category_id: 0,
        is_private: '',
        is_analyze_individual: 0,
        description: constants.SURVEY_DESCRIPTION,
      },
      template: {
        ID: 0,
        name: '',
      },
      defaultDescription: constants.SURVEY_DESCRIPTION,
      copySurvey: '',
      category: {},
      company: '',
      companies: [],
      project: '',
      projects: [],
      surveyStatus: constants.SURVEY_STATUS,
      surveyFrequency: constants.SURVEY_FREQUENCY.map(frequency => frequency.name),
      surveySequence: constants.SURVEY_SEQUENCE,
      categoryOptions: [],
      templateOptions: [],
      selectdQuestions: [],
      allQuestions: [],
      selectdCategories: [],
      allSurveys: [],
      required,
      currentPage: 1,
      questionType: constants.QUESTION_TYPE,
      language: '',
      languageOptions: constants.LANGUAGES,
      scope: '',
      scopeOptions: constants.SURVEY_SCOPE,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your Survey description',
    }
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
      editorOption,
      formatDate,
      resolveUserRole,
      avatarText,
    }
  },
  async created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.survey.ID = parseInt(router.currentRoute.params.id)
      if (this.survey.ID !== 0) {
        this.fetchSurveyByID(this.survey.ID).then(async response => {
          if (response) {
            this.survey = response.data
            this.setLanguage()
            this.setScope()
            await this.fetchProjects(this.survey.company_id)
            await this.fetchCompany()
            await this.fetchCategory()
            await this.setProject()
          }
        })
      }
    } else {
      await this.fetchCompany()
      await this.fetchCategory()
      this.fetchSurveys()
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('template', ['getAllCategories']),
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('project', ['getAllProjects']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories']),
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('project', ['fetchProjectByCompanyID']),
    ...mapActions('survey', [
      'createSurvey',
      'updateSurvey',
      'fetchSurveyByID',
      'fetchAllSurveys',
    ]),
    async fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
          if (this.survey.ID) {
            this.category = this.categoryOptions.find(
              i => i.ID === this.survey.category_id,
            )
          }
        }
      })
    },
    checkSurveyFrequency(length, frequency, startDate, endDate) {
      const surveyStartDate = new Date(startDate)
      const surveyEndDate = endDate ? new Date(endDate) : null

      if (frequency === constants.SURVEY_FREQUENCY[0].name && !surveyEndDate) {
        this.warningMessage(`The end date cannot be empty for the selected frequency: ${frequency}. Please provide a valid end date to match the survey frequency requirements`)
        return false
      }

      if (frequency !== constants.SURVEY_FREQUENCY[0].name) {
        const frequencyObj = constants.SURVEY_FREQUENCY.find(f => f.name === frequency)
        if (!frequencyObj) {
          this.warningMessage(`Frequency '${frequency}' is not valid.`)
          return false
        }

        if (surveyEndDate) {
          const diffTime = Math.abs(surveyEndDate - surveyStartDate)
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
          if (diffDays < frequencyObj.days) {
            this.warningMessage(`For ${frequency} frequency, start_date and end_date must be more than ${frequencyObj.days} days apart`)
            return false
          }
        }

        if (length > frequencyObj.days || length === 0) {
          if (length === 0) {
            this.warningMessage('The duration of the survey cannot be zero. Please provide a valid duration')
          } else {
            this.warningMessage(`For a '${frequency}' frequency, the duration should be ${frequencyObj.days} days, but currently, the duration is set to ${length} days`)
          }
          return false
        }
        return true
      }
      return true
    },
    fetchSurveys() {
      const payload = {
        page: this.currentPage,
      }
      try {
        this.fetchAllSurveys(payload).then(response => {
          this.allSurveys = response.allResult
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    handleChangeSurvey() {
      if (this.copySurvey.description) {
        this.survey.description = this.copySurvey.description
      } else {
        this.survey.description = this.defaultDescription
      }
    },
    setLanguage() {
      if (this.survey) {
        this.language = this.languageOptions.find(
          i => i.id === this.survey.language,
        )
      }
    },
    setScope() {
      if (this.survey) {
        this.scope = this.scopeOptions.find(
          i => i.id === this.survey.is_private,
        )
      }
    },
    async setProject() {
      if (this.survey.ID) {
        this.project = this.projects.find(
          i => i.ID === this.survey.project_id,
        )
      }
    },
    async fetchProjects(id) {
      await this.fetchProjectByCompanyID(id).then(() => {
        this.projects = this.getAllProjects
      })
    },
    handleChangeCompany(e) {
      this.projects = []
      this.project = ''
      this.fetchProjects(e.companyID)
    },
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
          if (this.survey.ID) {
            this.company = this.companies.find(
              i => i.companyID === this.survey.company_id,
            )
          } else if (this.companies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.companies[0]
            this.handleChangeCompany(this.company)
          }
        }
      })
    },
    clearReferenceID() {
      if (this.survey) {
        if (typeof this.survey.reference_id === 'string' && this.survey.reference_id === '') {
          this.survey.reference_id = null
        }
      }
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.survey.end_date = this.survey.end_date === '' ? null : this.survey.end_date
          const frequencyCheck = this.checkSurveyFrequency(this.survey.length, this.survey.frequency, this.survey.start_date, this.survey.end_date)
          if (frequencyCheck) {
            this.clearReferenceID()
            this.survey.company_id = this.company.companyID
            this.survey.project_id = this.project && this.project.ID !== null
              ? this.project.ID
              : 0
            this.survey.category_id = this.category && this.category.ID !== null ? this.category.ID : 0
            this.survey.language = this.language.id
            this.survey.is_private = this.scope.id
            if (this.survey.ID === 0) {
              this.survey.copy_id = this.copySurvey && this.copySurvey.ID !== null ? this.copySurvey.ID : 0
              this.createSurvey(this.survey)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.SURVEY_CREATED'))
                    this.$router.push({ name: 'survey-list' })
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            } else {
              this.survey = this.removeProperties(this.survey, [
                'record_status',
                'version',
                'created_by',
                'created_timestamp',
                'modified_by',
                'modified_timestamp',
                'is_analyze',
                'survey_questions',
                'survey_users',
                'feedback',
                'encryptID'])
              this.updateSurvey(this.survey)
                .then(response => {
                  if (response) {
                    this.successMessage(this.$i18n.t('MESSAGE.SURVEY_UPDATED'))
                    this.$router.push({ name: 'survey-list' })
                  }
                }).catch(error => {
                  if (error.response.data.status === 'failed') {
                    this.errorMessage(error.response.data.message.toString())
                  }
                })
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
